<template>
  <MDBCard class="m-3" :class="{ 'd-none':!Model }">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <MDBRow>
      <MDBCol md="12">
        <div class="card-box">
          <div class="panel-body">
            <div class="row">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table m-t-30">
                    <thead class="bg-faded">
                      <tr>
                        <th class="text-center">IP</th>
                        <th class="text-center">MAC 地址</th>
                        <th class="text-center">硬體</th>
                        <th class="text-center">CPU</th>
                        <th class="text-center">系統</th>
                      </tr>
                    </thead>
                    <tbody>
                        <template v-if="data.length > 0">
                            <template v-for="(item,k) in data" :key="k">
                                <tr >
                                    <th class="text-center">{{item.ip_address}}</th>
                                    <th class="text-center">{{item.mac_address}}</th>
                                    <th class="text-center">{{item.hardware}}</th>
                                    <th class="text-center">{{item.cpu}}</th>
                                    <th class="text-center">{{item.system}}</th>
                                </tr>
                            </template>
                        </template>
                     
                      <tr v-else>
                        <td colspan="5">無</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="hidden-print">
              <div class="float-right">
                <!-- <a href="javascript:window.print()" class="btn btn-dark waves-effect waves-light"><i class="fa fa-print"></i></a> -->
                <MDBBtn type="button" @click="Close">{{ $t("COMMON.CLOSE") }}</MDBBtn>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </MDBCol>
    </MDBRow>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
</template>

<style scope>
  .badge-info {
    color: #ffffff;
    background-color: #8950FC;
  }
  .badge-warning {
    color: #181C32;
    background-color: #FFA800;
  }
  .messageReplyContent * {
    white-space: normal;
    word-break: break-word;
  }
</style>

<script>
import { MDBContainer,MDBRow, MDBCol, MDBBtn, MDBCard, MDBCardBody } from 'mdb-vue-ui-kit';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { computed, ref } from "vue";

export default {
  name: "DeviceDetail",
  components: {
		MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBBtn,
    MDBCardBody,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    uuid: {
      type: String,
      default: ""
    },
  },
  setup(props, { emit }) {
    const Model = computed({
      get: () => props.modelValue,
      set: val => emit("update:modelValue", val)
    });
    const FormUUid = computed({
      get: () => props.uuid,
      set: val => emit("update:uuid", val),
    });
    const SubmitLoading = ref(false);
    const Common = CommonService;
    const data = ref([]);
    const Close = function() {
      FormUUid.value = "";
      Model.value = false;
    };

    const GetDetail = function() {
      Promise.all([
        new Promise(resolve => {
          ApiService.get("/Admin/v1/ActivationCode", FormUUid.value).then(response => {
            if (response.status == 200) {
              data.value = response.data.data;
              Model.value = true;
              resolve(response);
            }
          });
        })
      ]);
    };

	return {
      Model,
      SubmitLoading,
      FormUUid,
      Common,
      Close,
      data,
      GetDetail
    };
  },
  watch: {
    uuid: function(val){
      if(val){
        this.GetDetail();
      }
    }
  },
};
</script>