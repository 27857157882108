<template>
	<Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3" :class="{ 'd-none':(detail)}">
    <MDBCardBody>
	<MDBContainer fluid class="mt-3">
    <transition name="fade">
      <PageLoading v-if="PageLoading"></PageLoading>
    </transition>
		<DataTable
			:header="headers"
			:tabledata="desserts"
			v-model:total="total"
			v-model:options="options"
			v-model:loading="loading"
		>
			<!-- 時間轉換 -->
      <template v-slot:order="{ item }">
        {{ item.order[0].order }}
      </template>
      <template v-slot:product="{ item }">
        {{ (item.order[0].commodity.commodity)?((typeof item.order[0].commodity.commodity.name == "object")? item.order[0].commodity.commodity.name[lang].name: item.order[0].commodity.commodity.name ):"" }}
      </template>
      <template v-slot:version="{ item }">
        {{ (item.order[0].commodity.commodity)?((typeof item.order[0].commodity.commodity.version == "object")? item.order[0].commodity.commodity.version[lang].name: item.order[0].commodity.commodity.version ):"" }}
      </template>
      <template v-slot:updated_at="{ item }">
        {{ Common.TimeFormat(item.updated_at) }}
      </template>
      <template v-slot:uuid="{ item }">
        <MDBBtn color="primary" @click="DetailUUid = item.uuid" class="px-3 py-2">
          <MDBIcon icon="eye" iconStyle="fas" class="text-white"/>
        </MDBBtn>
      </template>
		</DataTable>
	</MDBContainer>
  </MDBCardBody>
  </MDBCard>
	<DeviceDetail v-model="detail" v-model:uuid="DetailUUid"></DeviceDetail>
</template>

<script>
import { MDBContainer, MDBCard, MDBCardBody, MDBBtn, MDBIcon } from 'mdb-vue-ui-kit';
import DataTable from '@/components/Plugin/DataTable';
import Breadcrumbs from '@/components/Breadcrumbs';
import DeviceDetail from '@/components/DeviceDetail';
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import PageLoading from '@/components/Elements/PageLoading';
import { ref, reactive } from "vue";
import { useI18n } from 'vue-i18n';
import i18nService from "@/core/services/i18n.service.js";

export default {
  name: "ActivationCode",
  components: {
		DataTable,
		MDBContainer,
		Breadcrumbs,
		DeviceDetail,
    MDBCard,
    MDBCardBody,
    PageLoading,
    MDBBtn,
    MDBIcon
  },
  setup() {
    const i18n = useI18n();
    const PageLoading = ref(false);
    const detail = ref(false);
    const loading = ref(true);
    const total = ref(0);
    const Common = CommonService;
    const DetailUUid = ref("");
    const desserts = ref([]);
    const lang = ref(i18nService.getActiveLanguage());

    const options = reactive({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["created_at"],
      sortDesc: ['desc'],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    });

    const headers = reactive([
      {
        text: i18n.t("COMMON.SEQUENCE"),
        align: "start",
        sortable: false,
        value: "no"
      },
      { text: i18n.t("COMMON.ORDERNUMBER"), value: "order" },
      { text: i18n.t("COMMON.PRODUCTNAME"), value: "product" },
      { text: i18n.t("COMMON.VERSION"), value: "version" },
      { text: i18n.t("COMMON.ACTIVATIONCODE"), value: "code" },
      { text: i18n.t("COMMON.OPERATE"), value: "uuid" },
    ]);

    const GetTable = function() {
      return new Promise(resolve => {
        loading.value = true;
        ApiService.query(
          "/Admin/v1/ActivationCode",
          {
            params: Object.assign(CommonService.SortHandleObject(options))
          }
        ).then(response => {
          if (response.status == 200) {
            desserts.value = CommonService.DataNoHandle(
              response.data.data.data,
              options.page,
              options.itemsPerPage
            );
            total.value = response.data.data.total;
            resolve(response);
          }
        });
      });
    };

		return {
      PageLoading,
      detail,
      loading,
      total,
      Common,
      options,
      headers,
      desserts,
      DetailUUid,
      GetTable,
      lang,
    };
  },
  watch: {
		options: {
      handler() {
        this.GetTable();
      },
      deep: true
    },
  },
  mounted() {
    this.GetTable();
  }
};
</script>